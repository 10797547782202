<template>
  <div class="container"></div>
</template>

<script>
import $fetch from '@/utils/fetch.js';
export default {
  async beforeCreate() {
    let { userId } = this.$route.query;
    $fetch
      .post("WeixinMiniAppShortUrl", {
        path: 'pages/profile/index',
        key: "017ccf71-a085-1080-4561-99377384d127",
        queryParams: { userId },
        type: 2,
      })
      .then((res) => {
        window.location.replace(res.short_url);
      });
  }
}
</script>

<style>

</style>
